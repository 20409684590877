<template>
    <div class="userFavorite">
        <div class="userFavorite_top">
            <div class="userFavorite_top_name">{{info.name}}</div>
            <div class="userFavorite_top_message"><span v-if="info.id != 0">创建时间：{{info.time}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>内容数量：{{info.total}}</div>
            <div class="userFavorite_edit" v-if="info.id != 0 && is_benren == 1" @click="editFun"><img src="../../assets/images/userFavorite_edit.png" alt="">编辑收藏夹</div>
        </div>
        <div class="userFavorite_box">
            <div class="userFavorite_list clearfix" v-if="info.lists&&info.lists.length>0">
                <!-- wow animate__animated animate__fadeInUp -->
                <div class="fl userFavorite_li " v-for="(item,index) in info.lists" :key="index" @click="toDetail(item.coll_id)">
                    <div class="userFavorite_li_box">
                        <img v-lazy="item.thumb" :key="item.thumb" alt="">
                        <!-- <img src="../../assets/images/image03_mrtj2x.png" alt=""> -->
                        <div class="userFavorite_li_del" v-if="is_benren == 1" @click.stop="delFun(item.coll_id)">移除</div>
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
            <!-- <page v-if="total>0" :page="page" :limit="limit" :total="total"></page> -->
        </div>
        <div class="mask" v-if="isShow">
            <favorite :id="id"></favorite>
        </div>
    </div>
</template>

<script>
// import { WOW } from 'wowjs'
import favorite from '../../components/favorite.vue'
export default {
    name:'userFavorite',
    components:{
        favorite
    },
    data(){
        return{
            id:'',//
            type:'',


            info:{},

            isShow:false,//编辑收藏夹

            is_benren:1,

            isFrom:2,//
        }
    },
    mounted(){
        // new WOW().init();
        this.is_benren = this.$parent.is_benren;
        if(this.$route.query.id){
            this.id = this.$route.query.id
        }
        if(this.$route.query.type){
            this.type = this.$route.query.type
        }if(this.$route.query.isFrom){
            this.isFrom = this.$route.query.isFrom
        }
        this.initFun()
    },
    methods:{
        initFun(){
            var that = this;
            this.$api.POST(this.$face.favoritesInfo,{id: this.id,type: this.type,user_id: this.$route.query.userId||''},function(res){
                that.info = res.data;
                that.info.time = that.info.create_time.substr(0,10)
            })
        },

        // 编辑
        editFun(){
            this.isShow = true
        },

        //移除收藏夹
        delFun(id){
            var that = this;
            this.$api.POST(this.$face.collectionCancelcoll,{coll_id: id,type: this.type,user_id: this.$route.query.userId||''},function(res){
                that.$utile.prompt('success',res.msg);
                that.initFun()
            })
        },
        //关闭收藏
        closeFun(){
            this.isShow = false
        },

        toDetail(id){
            if(this.isFrom == 1){
                this.$router.push({name:'projectDetail',query:{id:id}})
            }else if(this.isFrom == 2){
                this.$router.push({name:'materialDetail',query:{id:id}})
            }else{
                this.$router.push({name:'videoDetail',query:{id:id}})
            }
        }
    }
}
</script>

<style scoped>
.userFavorite{
    padding-top: 24px;
}
.userFavorite_top{
    width: 100%;
    height: 114px;
	background-color: #ffffff;
	border-radius: 8px;
    position: relative;
    padding: 27px 200px 27px 20px;
    box-sizing: border-box;
    margin-bottom: 23px;
}
.userFavorite_top_name{
    font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 22px;
}
.userFavorite_top_message{
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 14px;
	color: #999999;
}
.userFavorite_edit{
    position: absolute;
    top: 37px;
    right: 36px;
    width: 112px;
	height: 40px;
	background-color: #5957FF;
	border-radius: 8px;
    font-size: 14px;
	color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.userFavorite_edit img{
    margin-right: 5px;
}


.userFavorite_box{
    padding-bottom: 54px;
}
/* 专题 */
.userFavorite_list{
    margin: 34px -13.33px 45px;
}
.userFavorite_li{
    margin:0 13.33px 30px;
}
.userFavorite_li_box{
    width: 330px;
    height: 218px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.userFavorite_li_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
}
.userFavorite_li_del{
    width: 43px;
	height: 26px;
	background-color: rgba(0, 0, 0,.3);
	border-radius: 5px;
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 26px;
	letter-spacing: 0px;
	color: #ffffff;
    text-align: center;
    position: absolute;
    top: -50px;
    right: 16px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.userFavorite_li:hover .userFavorite_li_del{
    top: 15px;
    opacity: 1;
}
</style>