<template>
    <div class="collection">
        <div class="collection_head">
            编辑收藏夹
            <img @click="$parent.closeFun()" src="../assets/images/close.png" alt="">
        </div>
        <div class="favorite_input">
            <input type="text" v-model="name" placeholder="收藏夹名称" @keyup.enter="saveFun">
        </div>
        <div class="favorite_button">
            <div class="favorite_button_del" @click="delFun">删除收藏夹</div>
            <div class="favorite_button_save" @click="saveFun">
                确认
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'favorite',
    props:['id'],
    data() {
        return{
            name:'',
        }
    },
    mounted(){
        
    },
    methods:{
        // 删除
        delFun(){
            var that = this;
            var params ={
                id: this.id,
            }
            this.$api.POST(this.$face.favoritesDel,params,function(res){
                that.$utile.prompt('success','删除成功');
                that.$parent.initFun()
                that.$parent.closeFun();
            })
        },

        // 修改
        saveFun(){
            var that = this;
            var params ={
                id: this.id,
                name:this.name
            }
            this.$api.POST(this.$face.favoritesEdit,params,function(res){
                that.$parent.initFun()
                that.$parent.closeFun();
            }) 
        }
    }
}
</script>

<style scoped>
    .collection{
        position: absolute;
        background-color: #FFFFFF;
        width: 508px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        overflow: hidden;
    }
    .collection_head{
        width: 100%;
        height: 16px;
        padding: 25px 25px 0;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 16px;
        letter-spacing: 0px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 29px;
    }
    .collection_head img{
        transition: 1s;
    }
    .collection_head img:hover{
        transform: rotate(270deg);
    }

    .favorite_input{
        padding: 0 25px;
        margin-bottom: 28px;
    }
    .favorite_input input{
        width: 100%;
        height: 52px;
        background-color: #ffffff;
        border-radius: 10px;
        border: solid 1px #e5ebee;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: 0px;
        padding: 0 15px;
        color: #999999;
    }
    .favorite_button{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px 16px;
    }
    .favorite_button_del{
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #ff5967;
        cursor: pointer;
    }

    .favorite_button_save{
        width: 60px;
        height: 40px;
        background-color: #edf2f5;
        border-radius: 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 0px;
        color: #333333;
        text-align: center;
        transition: 0.3s;
        cursor: pointer;
    }
    .favorite_button_save:hover{
        background-color: #5957FF;
        color: #ffffff;
    }
</style>